const React = require('react');
const { connect } = require('react-redux');
const { loadCart } = require('../actions');

function CartWidget(props) {
	const { itemsInCart, dispatch } = props;
	return (
		<div className="cart-widget" onClick={() => dispatch(loadCart())}>
			<div className="cart-icon">
				<svg>
					<path d="M19.36,19.9V9.7a5.64,5.64,0,1,1,11.28,0V19.9" />
					<path d="M14.09,13.46a2.22,2.22,0,0,0-2.22,1.78L10,42.17A1.59,1.59,0,0,0,11.68,44H38.32A1.59,1.59,0,0,0,40,42.17L38.13,15.24a2.22,2.22,0,0,0-2.22-1.78Z" />
				</svg>
			</div>
			<div className="cart-icon-items">
				<div
					className={
						itemsInCart > 2
							? 'cart-third-counter'
							: 'cart-third-counter cart-counter-hidden'
					}
				/>
				<div
					className={
						itemsInCart > 1
							? 'cart-second-counter'
							: 'cart-second-counter cart-counter-hidden'
					}
				/>
				<div
					className={
						itemsInCart > 0
							? 'cart-counter'
							: 'cart-counter cart-counter-hidden'
					}
				>
					{Math.max(1, itemsInCart)}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		itemsInCart: state.cart.length
	};
}

module.exports = connect(mapStateToProps)(CartWidget);
