const React = require('react');

function wrapEach(className, items) {
	const elements = [];

	if (items) {
		items.forEach((item, index) => {
			elements.push(
				<div key={`item-${index}`} className={className}>
					{item}
				</div>
			);
		});
	}
	return elements;
}

function ProductWidget(props) {
	const { name, price, images, attributes, stock, onClick } = props;
	return (
		<div className="product-widget" onClick={onClick}>
			<div className="product-image-container">
				<div
					className="product-image"
					style={{ backgroundImage: `url(${images[0]})` }}
				/>
			</div>
			<div className="product-information">
				<div className="product-name">{name}</div>
				<div className="product-attributes">
					{wrapEach('product-attribute', attributes)}
				</div>
				<div className="product-order-information">
					<div className="product-price">{price}</div>
					<div className="product-stock">
						{stock ? (
							<span className="in-stock">In stock</span>
						) : (
							<span className="no-stock">Out of stock</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

module.exports = ProductWidget;
