const React = require('react');
const { connect } = require('react-redux');

const ProductWidget = require('./ProductWidget');
const { loadProduct } = require('../actions');

class Overview extends React.Component {
	renderProducts() {
		const { products, dispatch } = this.props;

		const productWidgets = [];

		products.forEach(product => {
			productWidgets.push(
				<ProductWidget
					key={`product-widget-${product.slug}`}
					onClick={() => dispatch(loadProduct(product.slug))}
					name={product.name}
					price={product.prettyPrice}
					images={product.images}
					attributes={product.attributes}
					stock={product.inStock}
					slug={product.slug}
				/>
			);
		});

		return productWidgets;
	}

	render() {
		return <div className="overview">{this.renderProducts()}</div>;
	}
}

function mapStateToProps(state) {
	return {
		products: state.page.products
	};
}

module.exports = connect(mapStateToProps)(Overview);
