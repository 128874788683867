// import { Elements } from 'react-stripe-elements';
import Checkout from './Checkout';

const React = require('react');
const { connect } = require('react-redux');
const { loadProduct, removeFromCart } = require('../actions');

function formatAsEuro(n) {
	let formatted = Math.round(n * 100) / 100;
	formatted = formatted.toString().replace('.', ',');
	formatted = `€${formatted}`;

	return formatted;
}

function decode(encoded) {
	const key = 'abcdefghijklmnopqrstuvwxyz@.-_ABCDEFGHIJKLMNOPQRSTUVWXYZ';

	let decoded = '';

	for (let j = 0; j < encoded.length; j++) {
		const c = encoded.charAt(j);
		const index = key.indexOf(c);
		let newIndex = (index - Math.ceil(key.length / 4)) % key.length;

		while (newIndex < 0) {
			newIndex += key.length;
		}

		decoded += key.charAt(newIndex);
	}

	return decoded;
}

class Cart extends React.Component {
	calculateTotal() {
		const { cart } = this.props;

		let total = 0;

		cart.forEach(item => {
			total += item.price;
		});

		return total;
	}

	renderProducts() {
		const { cart, dispatch } = this.props;

		const list = [];

		cart.forEach(product => {
			list.push(
				<div className="cart-item" key={`cart-item-${product.slug}`}>
					<div className="cart-item-image-container">
						<div
							className="cart-item-image"
							style={{ backgroundImage: `url("${product.images[0]}")` }}
						>
							{' '}
						</div>
					</div>
					<div
						className="cart-item-name"
						onClick={() => {
							dispatch(loadProduct(product.slug));
						}}
					>
						{product.name}
					</div>
					<div className="cart-item-price">{product.prettyPrice}</div>
					<div
						className="cart-item-remove"
						onClick={() => dispatch(removeFromCart(product.slug))}
					>
						{'×'}
					</div>
				</div>
			);
		});

		return list;
	}

	render() {
		const { cart, dispatch } = this.props;

		return (
			<div>
				<div className="cart">
					{cart.length > 0 ? (
						<div>
							<div className="cart-items">{this.renderProducts()}</div>
							<div className="cart-total">
								{formatAsEuro(this.calculateTotal())}
							</div>
							<div className="cart-note">
								{
									'Total includes shipping. After placing your order, we will contact you as soon as possible with payment information.'
								}
								<br />
								<br />
								{
									'We only ship to the Netherlands. For custom orders outside the Netherlands, please contact us at '
								}
								<a href={`mailto:${decode('vszz-KCE_sB_-CwDw-.Lqq')}`}>
									{decode('vszz-KCE_sB_-CwDw-.Lqq')}
								</a>
							</div>
							<Checkout amount={this.calculateTotal()} cart={cart} />
						</div>
					) : (
						<div className="cart-empty">Your cart is empty.</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cart: state.page.cart
	};
}

module.exports = connect(mapStateToProps)(Cart);
