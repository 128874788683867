import React from 'react';
// import { IdealBankElement, injectStripe } from 'react-stripe-elements';

class Checkout extends React.Component {
	constructor() {
		super();

		this.validateCheckout = this.validateCheckout.bind(this);

		this.state = {
			showMessage: false,
			message: '',
			success: false
		};
	}

	validateCheckout(e) {
		// const { stripe } = this.props;
		const { cart, amount } = this.props;

		e.preventDefault();

		const email = e.target.querySelector('input[name="email"]').value;
		const name = e.target.querySelector('input[name="name"]').value;
		const address = e.target.querySelector('input[name="address"]').value;
		const postal = e.target.querySelector('input[name="postal"]').value;
		const city = e.target.querySelector('input[name="city"]').value;

		let valid = true;

		if (
			!email.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
		) {
			e.target.querySelector('input[name="email"]').classList.add('invalid');
			valid = false;
		} else {
			e.target.querySelector('input[name="email"]').classList.remove('invalid');
		}

		if (name.length < 3) {
			e.target.querySelector('input[name="name"]').classList.add('invalid');
			valid = false;
		} else {
			e.target.querySelector('input[name="name"]').classList.remove('invalid');
		}

		if (address.length < 1) {
			e.target.querySelector('input[name="address"]').classList.add('invalid');
			valid = false;
		} else {
			e.target
				.querySelector('input[name="address"]')
				.classList.remove('invalid');
		}

		if (!postal.match(/[0-9]{4}[a-zA-Z]{2}/)) {
			e.target.querySelector('input[name="postal"]').classList.add('invalid');
			valid = false;
		} else {
			e.target
				.querySelector('input[name="postal"]')
				.classList.remove('invalid');
		}

		if (city.length < 1) {
			e.target.querySelector('input[name="city"]').classList.add('invalid');
			valid = false;
		} else {
			e.target.querySelector('input[name="city"]').classList.remove('invalid');
		}

		if (valid) {
			const items = [];
			cart.forEach(item => {
				items.push({
					name: item.name,
					price: item.price
				});
			});

			fetch('/api/v1/order', {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					amount,
					items,
					user: { name, email, address, postal, city }
				})
			})
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						this.setState({
							showMessage: true,
							success: true,
							message:
								'We have received your order. We will contact you as soon as possible regarding payment.'
						});

						localStorage.setItem('cart', '');
					} else {
						this.setState({
							showMessage: true,
							success: false,
							message: 'Oops... Something went wrong.'
						});
					}
				})
				.catch(() => {
					this.setState({
						showMessage: true,
						success: false,
						message: 'Oops... Something went wrong.'
					});
				});
		} else {
			this.setState({
				showMessage: true,
				success: false,
				message: 'Oops... Please check the marked fields.'
			});
		}

		// if (valid) {
		// stripe
		// 	.createSource({
		// 		type: 'ideal',
		// 		amount: this.props.amount,
		// 		currency: 'eur',
		// 		owner: {
		// 			name,
		// 			email,
		// 			address: `${address} ${postal} ${city}`
		// 		},
		// 		redirect: {
		// 			return_url: 'http://localhost:8080/order-confirmation'
		// 		}
		// 	})
		// 	.then(result => {
		// 		if (result.error) {
		// 			console.dir(result.error);
		// 		} else {
		// 			document.location.href = result.source.redirect.url;
		// 		}
		// 	});
		// }
	}

	render() {
		const { success, message, showMessage } = this.state;
		return (
			<form id="payment-form" onSubmit={this.validateCheckout}>
				<label htmlFor="email">E-mail address</label>
				<input name="email" placeholder="info@example.com" />
				<label htmlFor="name">Name</label>
				<input name="name" placeholder="Jan Janssen" />
				<label htmlFor="address">Street</label>
				<input name="address" placeholder="Stationsstraat 16A" />
				<label htmlFor="postal">Postal code</label>
				<input name="postal" placeholder="1234AA" />
				<label htmlFor="city">City</label>
				<input name="city" placeholder="Amsterdam" />
				<input type="submit" value="Place order" />
				{showMessage && (
					<span className={success ? 'message' : 'message error'}>
						{message}
					</span>
				)}
			</form>
		);
	}
}

module.exports = Checkout; // injectStripe(Checkout);
