const React = require('react');
const { connect } = require('react-redux');
const { loadOverview } = require('../actions');

const Overview = require('./Overview');
const Detail = require('./Detail');
const Cart = require('./Cart');

class Page extends React.Component {
	renderPage() {
		const { name } = this.props;

		switch (name) {
			case 'overview':
				return <Overview />;
			case 'detail':
				return <Detail />;
			case 'cart':
				return <Cart />;
			default:
				return (
					<div className="loader">
						<svg width="100%" height="100%" viewBox="0 0 128 128">
							<g>
								<linearGradient id="linear-gradient">
									<stop offset="0%" stopColor="#eee" fillOpacity="0" />
									<stop offset="100%" stopColor="#ccc" fillOpacity="1" />
								</linearGradient>
								<path
									d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
									fill="url(#linear-gradient)"
									fillRule="evenodd"
								/>
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 64 64"
									to="360 64 64"
									dur="720ms"
									repeatCount="indefinite"
								/>
							</g>
						</svg>
					</div>
				);
		}
	}

	render() {
		const { dispatch, showReturnButton } = this.props;
		return (
			<div className="page">
				{showReturnButton && (
					<div className="return" onClick={() => dispatch(loadOverview())}>
						<svg>
							<path d="M9.36,17.76h21.5a9.78,9.78,0,1,1,0,19.55H24M16.54,10.69,9.36,17.87l7,7" />
						</svg>
					</div>
				)}
				{this.renderPage()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		name: state.page ? state.page.name : null,
		showReturnButton:
			state.page &&
			state.page.name !== 'overview' &&
			state.page.name !== 'loading'
	};
}

module.exports = connect(mapStateToProps)(Page);
