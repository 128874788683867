export const SHOW_PRODUCTS = 'SHOW_PRODUCTS';

export function showProducts(products) {
	return {
		type: SHOW_PRODUCTS,
		products
	};
}

//

export const SHOW_PRODUCT = 'SHOW_PRODUCT';

export function showProduct(product) {
	return {
		type: SHOW_PRODUCT,
		product
	};
}

//

export const LOAD_OVERVIEW = 'LOAD_OVERVIEW';

export function loadOverview() {
	return {
		type: LOAD_OVERVIEW
	};
}

//

export const LOAD_PRODUCT = 'LOAD_PRODUCT';

export function loadProduct(product) {
	return {
		type: LOAD_PRODUCT,
		product
	};
}

//

export const ADD_TO_CART = 'ADD_TO_CART';

export function addToCart(product) {
	return {
		type: ADD_TO_CART,
		product
	};
}

//

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export function removeFromCart(product) {
	return {
		type: REMOVE_FROM_CART,
		product
	};
}

//

export const UPDATE_CART = 'UPDATE_CART';

export function updateCart(cb) {
	return {
		type: UPDATE_CART,
		cb
	};
}

//

export const SET_CART = 'SET_CART';

export function setCart(cart) {
	return {
		type: SET_CART,
		cart
	};
}

//

export const LOAD_CART = 'LOAD_CART';

export function loadCart() {
	return {
		type: LOAD_CART
	};
}

//

export const SHOW_CART = 'SHOW_CART';

export function showCart(cart) {
	return {
		type: SHOW_CART,
		cart
	};
}
