const React = require('react');
const { connect } = require('react-redux');
const { addToCart, removeFromCart } = require('../actions');

function wrapEach(className, items) {
	const elements = [];

	if (items) {
		items.forEach((item, index) => {
			elements.push(
				<div key={`item-${index}`} className={className}>
					{item}
				</div>
			);
		});
	}
	return elements;
}

class Detail extends React.Component {
	renderImages() {
		const { images } = this.props;

		const elements = [];

		images.forEach(image => {
			elements.push(
				<div className="product-image-container" key={`image-${image}`}>
					<div
						className="product-image"
						style={{ backgroundImage: `url(${image})` }}
					/>
				</div>
			);
		});
		return elements;
	}

	render() {
		const {
			name,
			dispatch,
			price,
			description,
			attributes,
			stock,
			onClick,
			slug,
			inCart
		} = this.props;

		return (
			<div>
				<div className="product-detail" onClick={onClick}>
					<div className="product-images">{this.renderImages()}</div>
					<div className="product-information">
						<div className="product-name">{name}</div>
						<div className="product-description">{description}</div>
						<div className="product-attributes">
							{wrapEach('product-attribute', attributes)}
						</div>
						<div className="product-order-information">
							<div className="product-price">{price}</div>
							<div className="product-stock">
								{stock ? (
									<span className="in-stock">In stock</span>
								) : (
									<span className="no-stock">Out of stock</span>
								)}
							</div>
							{stock && (
								<div
									className={
										inCart ? 'product-cart product-in-cart' : 'product-cart'
									}
									onClick={
										!inCart
											? () => {
													dispatch(addToCart(slug));
											  }
											: () => {
													dispatch(removeFromCart(slug));
											  }
									}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		name: state.page.product.name,
		price: state.page.product.prettyPrice,
		description: state.page.product.description,
		attributes: state.page.product.attributes,
		images: state.page.product.images,
		stock: state.page.product.inStock,
		slug: state.page.product.slug,
		inCart: state.cart.indexOf(state.page.product.slug) > -1
	};
}

module.exports = connect(mapStateToProps)(Detail);
