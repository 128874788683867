import '../../node_modules/reset-css/_reset.scss';
import './sass/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { StripeProvider } from 'react-stripe-elements';

import { createStore } from 'redux';
import {
	SHOW_PRODUCTS,
	SHOW_PRODUCT,
	LOAD_OVERVIEW,
	LOAD_PRODUCT,
	UPDATE_CART,
	ADD_TO_CART,
	SET_CART,
	LOAD_CART,
	SHOW_CART,
	REMOVE_FROM_CART,
	setCart,
	loadOverview,
	showCart,
	updateCart,
	showProducts,
	showProduct
} from './actions';
import Page from './components/Page';
import CartWidget from './components/CartWidget';

const defaultState = {
	cart: []
};
let store;

function syncCart() {
	const cartString = localStorage.getItem('cart');
	const cart = cartString ? cartString.split(' ') : [];
	const actualCart = [];

	return new Promise(resolve => {
		fetch(`/api/v1/products`)
			.then(response => response.json())
			.then(data => {
				const toRemove = [];

				cart.forEach(itemInCart => {
					let remove = true;

					data.forEach(product => {
						if (itemInCart === product.slug) {
							if (product.inStock) {
								remove = false;
								actualCart.push(product);
							}
						}
					});

					if (remove) toRemove.push(itemInCart);
				});

				toRemove.forEach(itemInCart => {
					cart.splice(cart.indexOf(itemInCart), 1);
				});

				store.dispatch(setCart(cart));

				resolve(actualCart);
			});
	});
}

function reduce(state = defaultState, action) {
	const cartString = localStorage.getItem('cart');
	const cart = cartString ? cartString.split(' ') : [];

	switch (action.type) {
		case UPDATE_CART:
			syncCart();

			return {
				...state
			};
		case SET_CART:
			localStorage.setItem('cart', action.cart.join(' '));

			return {
				...state,
				cart: action.cart
			};
		case LOAD_OVERVIEW:
			fetch('/api/v1/products')
				.then(response => response.json())
				.then(data => {
					store.dispatch(showProducts(data));
				});

			return {
				...state,
				page: {
					name: 'loading'
				}
			};
		case ADD_TO_CART:
			if (cart.indexOf(action.product) === -1) cart.push(action.product);
			localStorage.setItem('cart', cart.join(' '));

			return {
				...state,
				cart
			};
		case REMOVE_FROM_CART:
			const page = {
				...state.page
			};

			if (cart.indexOf(action.product) > -1) {
				if (page.cart) {
					page.cart = [...page.cart];

					let toRemove = -1;

					state.page.cart.forEach((item, index) => {
						if (item.slug === action.product) toRemove = index;
					});

					if (toRemove > -1) {
						page.cart.splice(toRemove, 1);
					}
				}

				cart.splice(cart.indexOf(action.product), 1);
				localStorage.setItem('cart', cart.join(' '));
			}

			return {
				...state,
				page,
				cart
			};
		case LOAD_PRODUCT:
			fetch(`/api/v1/products/${action.product}`)
				.then(response => response.json())
				.then(data => {
					store.dispatch(showProduct(data));
				});

			return {
				...state,
				page: {
					name: 'loading'
				}
			};
		case SHOW_PRODUCTS:
			return {
				...state,
				page: {
					name: 'overview',
					products: action.products
				}
			};
		case SHOW_PRODUCT:
			return {
				...state,
				page: {
					name: 'detail',
					product: action.product
				}
			};
		case SHOW_CART:
			return {
				...state,
				page: {
					name: 'cart',
					cart: action.cart
				}
			};
		case LOAD_CART:
			syncCart().then(actualCart => {
				store.dispatch(showCart(actualCart));
			});

			return {
				...state,
				page: {
					name: 'loading'
				}
			};
		default:
			return state;
	}
}

store = createStore(reduce);

ReactDOM.render(
	<Provider store={store}>
		<div className="full">
			<div className="header">
				<div className="title" onClick={() => store.dispatch(loadOverview())}>
					{'Superposition Store'}
				</div>
				<CartWidget />
			</div>
			<Page />
		</div>
	</Provider>,
	document.querySelector('.app')
);

store.dispatch(updateCart());
store.dispatch(loadOverview());
